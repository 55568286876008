import { Injectable } from '@angular/core';
import {
    AngularFirestoreCollection,
    AngularFirestore,
} from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    mandal = '';
    city = '';
    locationRef: AngularFirestoreCollection;

    constructor(public Afs: AngularFirestore) { }

    getCitiesall() {
        this.locationRef = this.Afs.collection('availablelocations')
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getDraftlocations() {
        this.locationRef = this.Afs.collection('draftlocations')
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getDraftleaders() {
        this.locationRef = this.Afs.collection('draftleaders')
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    public async deleteLeader(id) {
        this.locationRef = this.Afs.collection('draftleaders');
        await this.locationRef.doc(id).delete();
    }

    public async deleteLocation(id) {
        this.locationRef = this.Afs.collection('draftlocations');
        await this.locationRef.doc(id).delete();
    }

    public async approveLocation(item) {
        item.createdon = new Date();
        this.locationRef = this.Afs.collection('availablelocations');
        await this.locationRef.add({ ...item }).then((snapshot) => {
            console.log(snapshot);
        });
    }

    getLocation(id) {
        return this.Afs.collection("draftlocations").doc(id).ref.get();
    }

    getCities(district) {
        this.locationRef = this.Afs.collection('availablelocations', (ref) =>
            ref.where('entity', '==', 'mandal').where('district', '==', district));
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getVillages(mandal) {
        this.locationRef = this.Afs.collection('availablelocations', (ref) =>
            ref.where('entity', '==', 'village').where('mandal', '==', mandal));
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }


    getDistricts(state) {
        this.locationRef = this.Afs.collection('availablelocations', (ref) =>
            ref.where('entity', '==', 'district').where('state', '==', state)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getMandals(district) {
        this.locationRef = this.Afs.collection('availablelocations', (ref) =>
            ref.where('entity', '==', 'mandal').where('district', '==', district)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getStates() {
        this.locationRef = this.Afs.collection('availablelocations', (ref) =>
            ref.where('entity', '==', 'state')
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }



    getLocalities(mandal) {
        this.locationRef = this.Afs.collection('availablelocalities', (ref) =>
            ref.where('mandal', '==', mandal)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id, ...data };
                })
            )
        );
    }

    getLocationInfo(mandal, location) {
        this.locationRef = this.Afs.collection('availablelocalities', (ref) =>
            ref.where('mandal', '==', mandal).where('locality', '==', location)
        );
        let tempData = {};
        return this.locationRef.get().pipe(
            map(
                (res) =>
                    res.docs.map((d) => {
                        tempData = d.data();
                        tempData['id'] = d.id;
                        return tempData;
                    }),
                take(1)
            )
        );
    }

    getInchargeLocation(id) {
        this.locationRef = this.Afs.collection('availablelocalities', (ref) =>
            ref.where('inchargeid', '==', id)
        );
        return this.locationRef.snapshotChanges().pipe(
            map((res) =>
                res.map((dataItems) => {
                    const data = dataItems.payload.doc.data(),
                        id = dataItems.payload.doc.id;
                    return { id: dataItems.payload.doc.id, ...data };
                })
            )
        );
    }

    updateIncharge(userId, userName, locationId) {
        this.locationRef = this.Afs.collection('availablelocalities');
        return this.locationRef
            .doc(locationId)
            .update({ inchargeid: userId, inchargename: userName });
    }

    public async createCity(result) {
        // result.createdbyid = localStorage.getItem('uid');
        result.createdbyname = localStorage.getItem('name');
        result.createdon = new Date();
        this.locationRef = this.Afs.collection('availablelocations');
        await this.locationRef.add({ ...result }).then((snapshot) => {
            console.log(snapshot);
        });
    }

    public async updateDraftLocation(id, result, user) {
        result.createdbyid = user;
        result.updatedbyid = localStorage.getItem("uid");
        result.updatedbyname = localStorage.getItem("name");
        result.status = 'Location Approved',
            result.updatedon = new Date();
        this.locationRef = this.Afs.collection("draftlocations");
        await this.locationRef.doc(id).set({ ...result });
    }

    public async updateDraftLeader(id, result, user) {
        result.createdbyid = user;
        result.updatedbyid = localStorage.getItem("uid");
        result.updatedbyname = localStorage.getItem("name");
        result.status = 'Leader Approved',
        result.updatedon = new Date();
        this.locationRef = this.Afs.collection("draftlocations");
        await this.locationRef.doc(id).set({ ...result });
    }


    public async createLocality(result) {
        result.createdbyid = localStorage.getItem('uid');
        result.createdbyname = localStorage.getItem('name');
        result.createdon = new Date();
        this.locationRef = this.Afs.collection('availablelocalities');
        await this.locationRef.add({ ...result }).then((snapshot) => {
            console.log(snapshot);
        });
    }
}

export class City {
    name: string;
    mandal: string;
    state: string;
    country: string;
    district: string;
    entity: string;
    villages: number;
    corporators: number;
    sarpanchs: number;
    policestations: number;
    mandals: number;
    hospitals: number;
    registrationoffices: number;

    constructor() {
        this.name = '';
        this.mandal = '';
        this.state = '';
        this.country = '';
        this.villages = 0;
        this.hospitals = 0;
        this.registrationoffices = 0;
        this.corporators = 0;
        this.sarpanchs = 0;
        this.policestations = 0;
        this.mandals = 0;
        this.district = '';
        this.entity = '';
    }
}
