import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    // {
    //   path: '',
    //   redirectTo: 'sign-in',
    //   pathMatch: 'full',
    // },
    {
        path: 'sign-in',
        loadChildren: () =>
            import('./sign-in/sign-in.module').then((m) => m.SignInPageModule),
    },
    {
        path: 'sign-up',
        loadChildren: () =>
            import('./sign-up/sign-up.module').then((m) => m.SignUpPageModule),
    },
    {
        path: 'verification',
        loadChildren: () =>
            import('./verification/verification.module').then(
                (m) => m.VerificationPageModule
            ),
    },
    {
        path: 'access-location',
        loadChildren: () =>
            import('./access-location/access-location.module').then(
                (m) => m.AccessLocationPageModule
            ),
    },
    {
        path: 'my-stores',
        loadChildren: () =>
            import('./my-stores/my-stores.module').then((m) => m.MyStoresPageModule),
    },
    {
        path: 'all-stores',
        loadChildren: () =>
            import('./all-stores/all-stores.module').then((m) => m.AllStoresPageModule),
    },
    {
        path: 'request-access',
        loadChildren: () =>
            import('./request-access/request-access.module').then(
                (m) => m.RequestAccessPageModule
            ),
    },
    {
        path: 'request-status',
        loadChildren: () =>
            import('./request-status/request-status.module').then(
                (m) => m.RequestStatusPageModule
            ),
    },
    {
        path: 'request-incharge',
        loadChildren: () =>
            import('./request-incharge/request-incharge.module').then(
                (m) => m.RequestInchargePageModule
            ),
    },
    {
        path: 'request-admin',
        loadChildren: () =>
            import('./request-admin/request-admin.module').then(
                (m) => m.RequestAdminPageModule
            ),
    },
    {
        path: 'request-staff',
        loadChildren: () =>
            import('./request-staff/request-staff.module').then(
                (m) => m.RequestStaffPageModule
            ),
    },
    {
        path: 'request-deliverer',
        loadChildren: () =>
            import('./request-deliverer/request-deliverer.module').then(
                (m) => m.RequestDelivererPageModule
            ),
    },
    {
        path: 'new-orders',
        loadChildren: () =>
            import('./new-orders/new-orders.module').then(
                (m) => m.NewOrdersPageModule
            ),
    },
    {
        path: 'edit-item',
        loadChildren: () =>
            import('./edit-item/edit-item.module').then((m) => m.EditItemPageModule),
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./profile/profile.module').then((m) => m.ProfilePageModule),
    },
    {
        path: 'add-address',
        loadChildren: () =>
            import('./add-address/add-address.module').then(
                (m) => m.AddAddressPageModule
            ),
    },
    {
        path: 'contact-us',
        loadChildren: () =>
            import('./contact-us/contact-us.module').then(
                (m) => m.ContactUsPageModule
            ),
    },
    {
        path: 'language',
        loadChildren: () =>
            import('./language/language.module').then((m) => m.LanguagePageModule),
    },
    {
        path: 'title',
        loadChildren: () =>
            import('./title/title.module').then((m) => m.TitlePageModule),
    },
    {
        path: 'insight',
        loadChildren: () =>
            import('./insight/insight.module').then((m) => m.InsightPageModule),
    },
    {
        path: 'notifications',
        loadChildren: () =>
            import('./notifications/notifications.module').then(
                (m) => m.NotificationsPageModule
            ),
    },
    {
        path: 'businesses',
        loadChildren: () =>
            import('./my-businesses/my-businesses.module').then(
                (m) => m.MyBusinessesPageModule
            ),
    },
    {
        path: 'categories',
        loadChildren: () =>
            import('./my-categories/my-categories.module').then(
                (m) => m.MyCategoriesPageModule
            ),
    },
    {
        path: 'add-store',
        loadChildren: () =>
            import('./add-store/add-store.module').then((m) => m.AddStorePageModule),
    },
    {
        path: 'add-city',
        loadChildren: () =>
            import('./add-city/add-city.module').then((m) => m.AddCityPageModule),
    },
    {
        path: 'add-business',
        loadChildren: () =>
            import('./add-business/add-business.module').then(
                (m) => m.AddBusinessPageModule
            ),
    },
    {
        path: 'add-category',
        loadChildren: () =>
            import('./add-category/add-category.module').then(
                (m) => m.AddCategoryPageModule
            ),
    },
    {
        path: 'add-product',
        loadChildren: () =>
            import('./add-product/add-product.module').then(
                (m) => m.AddProductPageModule
            ),
    },
    {
        path: 'terms',
        loadChildren: () => import('./terms/terms.module').then(m => m.TermsPageModule)
    },
  {
    path: 'draft-locations',
    loadChildren: () => import('./draft-locations/draft-locations.module').then( m => m.DraftLocationsPageModule)
  },


  {
    path: 'add-party',
    loadChildren: () => import('./add-party/add-party.module').then( m => m.AddPartyPageModule)
  },
  {
    path: 'add-schemes',
    loadChildren: () => import('./add-schemes/add-schemes.module').then( m => m.AddSchemesPageModule)
  },
  {
    path: 'draft-leaders',
    loadChildren: () => import('./draft-leaders/draft-leaders.module').then( m => m.DraftLeadersPageModule)
  },
  {
    path: 'add-leader',
    loadChildren: () => import('./add-leader/add-leader.module').then( m => m.AddLeaderPageModule)
  },





];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
