import { Component, OnInit, Inject } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from './app.config';
import { MyEvent } from 'src/services/myevent.services';
import { Constants } from 'src/models/contants.models';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/services/utility.service';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';

import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    isLoggedIn = false;
    uid = '';
    name = '';
    phone = '';
    role = 'user';
    rtlSide = 'left';
    public selectedIndex = 0;
    public appPages = [];

    constructor(
        @Inject(APP_CONFIG) public config: AppConfig,
        private platform: Platform,
        private navCtrl: NavController,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private authService: AuthService,
        private utils: UtilityService,
        private router: Router,
        private myEvent: MyEvent,
    ) {
        this.initializeApp();
        this.myEvent.getLanguageObservable().subscribe((value) => {
            this.navCtrl.navigateRoot(['./']);
            this.globalize(value);
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            // this.statusBar.styleDefault();
            // this.splashScreen.hide();

            // FCM.onNotification().subscribe((data) => {
            //     console.log(data);
            //     if (data.wasTapped) {
            //       console.log('Received in background');
            //     } else {
            //       console.log('Received in foreground');
            //     }
            //   });

            //   // refresh the FCM token
            //   FCM.onTokenRefresh().subscribe((token) => {
            //     console.log(token);
            //   });

            const defaultLang = window.localStorage.getItem(
                Constants.KEY_DEFAULT_LANGUAGE
            );
            this.globalize(defaultLang);
        });
    }

    globalize(languagePriority) {
        this.translate.setDefaultLang('en');
        const defaultLangCode = this.config.availableLanguages[0].code;
        this.translate.use(
            languagePriority && languagePriority.length
                ? languagePriority
                : defaultLangCode
        );
        this.setDirectionAccordingly(
            languagePriority && languagePriority.length
                ? languagePriority
                : defaultLangCode
        );
    }

    setDirectionAccordingly(lang: string) {
        switch (lang) {
            case 'ar': {
                this.rtlSide = 'rtl';
                break;
            }
            default: {
                this.rtlSide = 'ltr';
                break;
            }
        }
    }

    ngOnInit() {
        this.checkLogin();
        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(
                (page) => page.title.toLowerCase() === path.toLowerCase()
            );
        }
        //firebasex
        //     this.firebaseX.getToken()
        //     .then(token => console.log(`The token is ${token}`)) // save the token server-side and use it to push notifications to this device
        //     .catch(error => console.error('Error getting token', error));

        //   this.firebaseX.onMessageReceived()
        //     .subscribe(data => console.log(`User opened a notification ${data}`));

        //   this.firebaseX.onTokenRefresh()
        //     .subscribe((token: string) => console.log(`Got a new token ${token}`));
    }
    checkLogin() {
        this.isLoggedIn = this.authService.checkLogin();
        if (this.isLoggedIn) {
            this.name = localStorage.getItem('name');
            this.utils.name = this.name;
            this.phone = localStorage.getItem('phonenumber');
            this.utils.phone = this.phone;
            this.uid = localStorage.getItem('uid');
            this.utils.uid = this.uid;
            this.authService.getUserDetails(this.uid).then((res) => {
                this.role = res.data()['role'];
                this.utils.role = this.role;
                localStorage.setItem('role', this.role);


                // checkLogin() {
                //     this.isLoggedIn = this.authService.checkLogin();
                //     if (!this.isLoggedIn) {
                //         this.name ='Silveri Rishi';    // localStorage.getItem('name'); //
                //         this.utils.name = this.name;
                //         this.phone = '7095959589';  // localStorage.getItem('phonenumber'); //
                //         this.utils.phone = this.phone;
                //         this.uid = 's1KoO8XKAybexCyEC0w1tYp7AgG3'; //  localStorage.getItem('uid');  //
                //         this.utils.uid = this.uid;
                //         this.authService.getUserDetails().then((res) => {
                //             const data: any = res.data();
                //             this.role = data.role;
                //             this.utils.role = this.role;
                //             localStorage.setItem('role', this.role);


                if (this.role === 'deliverer' || this.role === 'staff') {
                    this.appPages = [
                        {
                            title: 'my_profile',
                            url: '/profile',
                            icon: 'zmdi zmdi-assignment-account',
                        },
                        {
                            title: 'my_orders',
                            url: '/new-orders',
                            icon: 'zmdi zmdi-shopping-cart',
                        },
                        {
                            title: 'Request Access',
                            url: '/request-access',
                            icon: 'zmdi zmdi-globe',
                        },
                    ];
                } else if (this.role === 'admin') {
                    this.appPages = [
                        {
                            title: 'my_profile',
                            url: '/profile',
                            icon: 'zmdi zmdi-assignment-account',
                        },
                        {
                            title: 'my_orders',
                            url: '/new-orders',
                            icon: 'zmdi zmdi-shopping-cart',
                        },
                        {
                            title: 'My Categories',
                            url: '/categories',
                            icon: 'zmdi zmdi-tag',
                        },
                        {
                            title: 'Request Access',
                            url: '/request-access',
                            icon: 'zmdi zmdi-globe',
                        },
                        {
                            title: 'terms',
                            url: '/terms',
                            icon: 'zmdi zmdi-file-text',
                        },
                        {
                            title: 'My-appointments',
                            url: '/my-appointments',
                            icon: 'zmdi zmdi-file-text',
                        },
                    ];
                } else if (this.role === 'incharge') {
                    this.appPages = [
                        {
                            title: 'my_profile',
                            url: '/profile',
                            icon: 'zmdi zmdi-assignment-account',
                        },
                        {
                            title: 'Add Store',
                            url: '/add-store',
                            icon: 'zmdi zmdi-plus-circle',
                        },
                        {
                            title: 'Draft Locations',
                            url: '/draft-location',
                            icon: 'zmdi zmdi-plus-circle',
                        },
                        {
                            title: 'All Stores',
                            url: '/all-stores',
                            icon: 'zmdi zmdi-shopping-cart',
                        },
                        {
                            title: 'my_earnings',
                            url: '/earnings',
                            icon: 'zmdi zmdi-balance-wallet',
                        },
                        {
                            title: 'terms',
                            url: '/terms',
                            icon: 'zmdi zmdi-file-text',
                        },
                    ];
                } else if (this.role === 'superadmin') {
                    this.appPages = [
                        {
                            title: 'my_profile',
                            url: '/profile',
                            icon: 'zmdi zmdi-assignment-account',
                        },
                        {
                            title: 'My Businesses',
                            url: '/businesses',
                            icon: 'zmdi zmdi-tag',
                        },
                        {
                            title: 'Add Location',
                            url: '/add-city',
                            icon: 'zmdi zmdi-pin',
                        },
                        {
                            title: 'Requested Locations',
                            url: '/draft-locations',
                            icon: 'zmdi zmdi-gps-dot',
                        },
                        {
                            title: 'Requested Leaders',
                            url: '/draft-leaders',
                            icon: 'zmdi zmdi-gps-dot',
                        },
                        {
                            title: 'Add Locality',
                            url: '/add-locality',
                            icon: 'zmdi zmdi-pin',
                        },
                        {
                            title: 'Add Store',
                            url: '/add-store',
                            icon: 'zmdi zmdi-plus-circle',
                        },
                        {
                            title: 'Add Leader',
                            url: '/add-leader',
                            icon: 'zmdi zmdi-plus-circle',
                        },
                        {
                            title: 'Add Party',
                            url: '/add-party',
                            icon: 'zmdi zmdi-plus-circle',
                        },
                        {
                            title: 'Add Schemes',
                            url: '/add-schemes',
                            icon: 'zmdi zmdi-plus-circle',
                        },
                        
                        {
                            title: 'All Stores',
                            url: '/all-stores',
                            icon: 'zmdi zmdi-shopping-cart',
                        },
                        {
                            title: 'insight',
                            url: '/insight',
                            icon: 'zmdi zmdi-chart',
                        },
                    ];
                } else {
                    this.appPages = [
                        {
                            title: 'my_profile',
                            url: '/profile',
                            icon: 'zmdi zmdi-assignment-account',
                        },
                        {
                            title: 'Request Access',
                            url: '/request-access',
                            icon: 'zmdi zmdi-globe',
                        },
                        {
                            title: 'terms',
                            url: '/terms',
                            icon: 'zmdi zmdi-file-text',
                        },
                    ];
                }
                this.authService.routeUser(this.role);
            });
        } else {
            this.router.navigate(['sign-in']);
        }
    }

    onActivate() {
        this.isLoggedIn = this.authService.checkLogin();
        if (this.isLoggedIn) {
            this.name = localStorage.getItem('name');
            this.phone = localStorage.getItem('phonenumber');
            this.role = localStorage.getItem('role');
        }
    }

    login() {
        this.router.navigate(['./sign-in']);
    }

    logout() {
        this.isLoggedIn = false;
        this.authService.logout();
    }
}
