import { Injectable } from "@angular/core";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { FirebaseAuthentication } from "@ionic-native/firebase-authentication/ngx";
import { Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { UtilityService } from "./utility.service";
import { RequestService } from "./request.service";
import { LocationService } from "./location.service";

import * as firebase from 'firebase';


@Injectable({
  providedIn: "root",
})
export class AuthService {
  usersRef: AngularFirestoreCollection;

  constructor(
    private firebase: FirebaseX,
    private fireAuth: FirebaseAuthentication,
    private route: Router,
    public Afs: AngularFirestore,
    private utils: UtilityService,
    private requestService: RequestService,
    private locationService: LocationService,
 
  ) {}

  get windowRef(){
    return window;
}

  checkLogin(): boolean {
    if (localStorage.getItem("isloggedin") === "true") {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    localStorage.removeItem("isloggedin");
    localStorage.removeItem("phonenumber");
    localStorage.removeItem("uid");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    this.fireAuth.signOut();
    this.route.navigate(["./sign-in"]);
  }

  getOtp(phoneNumber) {
    return this.firebase.verifyPhoneNumber("+" + phoneNumber, 60);
  }

  verifyOtp(verificationId, otp) {
    return this.fireAuth.signInWithVerificationId(
      verificationId,
      otp.toString()
    );
  }

  getUserInfo() {
    return this.firebase.getCurrentUser();
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        return user
        console.log('user signed')
      } else {
        // No user is signed in.
        console.log('user not signed')
        return null
      }
    });
    
  }

  getUsers(type) {
    this.usersRef = this.Afs.collection("users", (ref) =>
      ref.where("role", "==", type)
    );
    return this.usersRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    console.log('id');
  }

  checkUser(userId) {
    if (userId) {
      this.usersRef = this.Afs.collection("users");
      return this.usersRef
        .doc(userId)
        .snapshotChanges()
        .pipe(
          map((res: any) => {
            const result = res.payload.data();
            if (result) {
              result.id = res.payload.id;
            }
            return result;
          })
        );
    }
  }

  createUser(name,uid) {
    this.usersRef = this.Afs.collection("users");
    this.usersRef
      .doc(uid)
      .set({
        fullname: name,
        createdon: new Date(),
        role: "user",
      })
      .then((snapshot) => {
        localStorage.setItem("isloggedin", "true");
        localStorage.setItem("name", name);
        localStorage.setItem("role", "user");
        this.utils.isLoggedIn = true;
        this.utils.name = name;
        this.utils.role = "user";
        this.route.navigate(["request-access"]);
      });
  }

  

  updateUserRole(userId, role) {
    this.usersRef = this.Afs.collection("users");
    return this.usersRef.doc(userId).update({
      role: role,
    });
  }

  updateUserName(userId, name) {
    this.usersRef = this.Afs.collection("users");
    return this.usersRef.doc(userId).update({
      fullname: name,
    });
  }

  routeUser(type: string) {
    if (type === "superadmin") {
      this.route.navigate(["./insight"]);
      return;
    }
    if (type === "incharge") {
      this.handleInchargeRoute();
      return;
    }
    if (type === "admin") {
      this.handleStoreRoute();
      return;
    }
    if (type === "staff") {
      this.handleStoreRoute();
      return;
    }
    if (type === "deliverer") {
      this.handleStoreRoute();
      return;
    }
    if (type === "user") {
      this.handleUserRoute();
      return;
    }
  }

  handleStoreRoute() {
    if (localStorage.getItem(this.utils.uid + "currentstoreid") !== null) {
      const navigationExtras = {
        queryParams: {
          shopId: localStorage.getItem(this.utils.uid + "currentstoreid"),
        },
      };
      this.route.navigate(["./new-orders"], navigationExtras);
    } else {
      this.route.navigate(["./my-stores"]);
    }
  }

  handleInchargeRoute() {
    this.locationService
      .getInchargeLocation(this.utils.uid)
      .subscribe((data) => {
        localStorage.setItem(this.utils.uid + "city", data[0]["city"]);
        localStorage.setItem(this.utils.uid + "locality", data[0]["locality"]);
        this.route.navigate(["./earnings"]);
      });
  }

  handleUserRoute() {
    this.requestService
      .getUserRequests(this.utils.uid)
      .subscribe((requests) => {
        this.utils.requests = requests;
        if (this.utils.requests.length === 0) {
          this.route.navigate(["request-access"]);
        } else {
          this.route.navigate(["request-status"]);
        }
      });
  }

  getUserDetails(uid) {
    return this.Afs.collection("users").doc(uid).ref.get();
  }
  // getUserDetail() {
  //   return firebase.firestore().collection("users").doc(this.utils.uid).ref.get();
  // }

}