import { Injectable } from "@angular/core";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  requestRef: AngularFirestoreCollection;

  constructor(public Afs: AngularFirestore) {}

  public addRequest(record) {
    this.requestRef = this.Afs.collection("requests");
    return this.requestRef.add({ ...record });
  }

  public deleteRequest(id) {
    this.requestRef = this.Afs.collection("requests");
    return this.requestRef.doc(id).delete();
  }

  getUserRequests(id: string) {
    this.requestRef = this.Afs.collection("requests", (ref) =>
      ref.where("to", "array-contains", id)
    );
    return this.requestRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }
}
